import { isEmpty, isEqual, isNil, map, size } from 'lodash';
import Link from 'next/link';
import { Fragment } from 'react';
import { BreadScrumb } from '../../../../graphql/main/queries/slugify';

interface Props {
  breadcrumbs?: BreadScrumb[];
}

const Breadcrumb = ({ breadcrumbs }: Props) => {
  const cloneBreadcrumbs = breadcrumbs
    ? isEqual(size(breadcrumbs), 1)
      ? [
          {
            name: 'Trang chủ',
            slug: '',
            tooltip: 'Trang chủ',
          },
          ...breadcrumbs,
        ]
      : [...breadcrumbs]
    : [];

  return !isEmpty(breadcrumbs) ? (
    <div id='breadcrumb' className='mx-[-2px] flex flex-wrap items-center'>
      {map(cloneBreadcrumbs, (breadcrumb, breadcrumbIndex) => (
        <Fragment key={breadcrumbIndex}>
          {!isEqual(breadcrumbIndex, 0) && (
            <span className='cursor-default px-[2px] text-primary'>/</span>
          )}
          <Link href={isNil(breadcrumb.slug) ? '#' : `/${breadcrumb.slug}`}>
            <a>
              <span
                className={`whitespace-nowrap px-[2px] ${
                  !isEqual(breadcrumbIndex, size(cloneBreadcrumbs) - 1) ? 'text-primary' : ''
                }`}
              >
                {breadcrumb.name}
              </span>
            </a>
          </Link>
        </Fragment>
      ))}
    </div>
  ) : null;
};

export default Breadcrumb;
