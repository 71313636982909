import { Popover } from '@headlessui/react';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { filter, includes, isEmpty, isEqual, map, toString } from 'lodash';
import { ChangeEvent, Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { removeVietnameseAccents } from 'utils';

interface Props {
  onSubmit?: () => void;
}

const RealEstateTypePopover = ({ onSubmit }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const translation = useTranslation();
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const { major } = useConfigurationContext();
  const form = useFormContext<SearchForm1>();
  const demandWatch = useWatch({ control: form.control, name: 'demand' });
  const realEstateTypesWatch = useWatch({ control: form.control, name: 'realEstateTypes' });
  const realEstateTypes = map(
    isEqual(demandWatch, 'rent')
      ? major?.common?.forRentRealEstateTypes
      : major?.common?.forSellRealEstateTypes,
    (realEstateType) => realEstateType.key,
  );
  const realEstateTypeLabel =
    (isEqual(demandWatch, 'rent')
      ? translation.major.forRentRealEstateType
      : (translation.major.forSellRealEstateType as any))[toString(realEstateTypesWatch)] ||
    'Loại BĐS';
  const [privateRealEstateTypes, setPrivateRealEstateTypes] = useState<
    (string | undefined)[] | undefined
  >();

  const handleOnChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleOnClearSearchInput = () => {
    setSearchValue('');
  };
  const handleOnSelectRealEstateType = (realEstateType: string) => {
    form.setValue('realEstateTypes', realEstateType as any);
    setSearchValue('');
    popoverButtonRef.current?.click();
    onSubmit?.();
  };
  const handleOnReset = () => {
    form.setValue('realEstateTypes', undefined);
    setSearchValue('');
    popoverButtonRef.current?.click();
    onSubmit?.();
  };

  useEffect(() => {
    setPrivateRealEstateTypes(
      filter(realEstateTypes, (realEstateType) =>
        includes(
          removeVietnameseAccents(
            (isEqual(demandWatch, 'rent')
              ? translation.major.forRentRealEstateType
              : (translation.major.forSellRealEstateType as any))[toString(realEstateType)],
          ),
          removeVietnameseAccents(searchValue),
        ),
      ),
    );
  }, [searchValue]);
  useEffect(() => {
    setPrivateRealEstateTypes(realEstateTypes);
  }, [demandWatch]);
  useEffect(() => {
    setPrivateRealEstateTypes(realEstateTypes);
  }, [major]);

  return (
    <Popover
      id='real-estate-type-popover'
      className='relative flex max-w-[156px] flex-col space-y-[8px]'
    >
      {({ open }) => (
        <Fragment>
          <Popover.Button
            type='button'
            ref={popoverButtonRef}
            className={`flex h-full items-center space-x-[8px] overflow-x-hidden transition duration-[200ms] ease-in-out hover:text-primary ${
              open ? 'text-primary' : ''
            }`}
          >
            <span className='truncate text-left'>{realEstateTypeLabel}</span>
            <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px]' />
          </Popover.Button>
          <Popover.Panel className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[16px]'>
            <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
              <div className='group mx-[16px] flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <input
                  type='text'
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  placeholder='Nhập từ khoá tìm kiếm'
                  value={searchValue}
                  onChange={(event) => {
                    handleOnChangeSearchInput(event);
                  }}
                />
                {Boolean(searchValue) && (
                  <button
                    type='button'
                    className='ml-[8px] hidden group-hover:flex'
                    onClick={handleOnClearSearchInput}
                  >
                    <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                  </button>
                )}
              </div>
              {isEmpty(privateRealEstateTypes) ? (
                <span className='block h-full px-[16px] italic text-text2'>Không có tuỳ chọn</span>
              ) : (
                <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                  {map(privateRealEstateTypes, (realEstateType, realEstateTypeIndex) => (
                    <li key={`${realEstateType}-${realEstateTypeIndex}`}>
                      <button
                        type='button'
                        className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                        onClick={() => {
                          realEstateType && handleOnSelectRealEstateType(realEstateType);
                        }}
                      >
                        <div
                          className={`h-[20px] w-[20px] rounded-full border ${
                            isEqual(realEstateTypesWatch, realEstateType)
                              ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                              : 'border-stroke'
                          }`}
                        />
                        <span className='line-clamp-1'>
                          {
                            (isEqual(demandWatch, 'rent')
                              ? translation.major.forRentRealEstateType
                              : (translation.major.forSellRealEstateType as any))[
                              toString(realEstateType)
                            ]
                          }
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              )}
              <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
                <button
                  type='button'
                  className='flex items-center justify-center space-x-[6px]'
                  onClick={handleOnReset}
                >
                  <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                  <span>Đặt lại</span>
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Fragment>
      )}
    </Popover>
  );
};

export default RealEstateTypePopover;
