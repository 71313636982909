import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { isEqual, map } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
  onChange?: () => void;
}

const DemandButton = ({ onChange }: Props) => {
  const translation = useTranslation();
  const form = useFormContext<SearchForm1>();
  const demandWatch = useWatch({ control: form.control, name: 'demand' });
  const demands = [
    {
      label: translation.desktop.home.banner.searchTool.forBuy,
      value: 'sell',
    },
    {
      label: translation.desktop.home.banner.searchTool.forRent,
      value: 'rent',
    },
  ];

  const handleOnChangeDemand = (value: string) => {
    form.setValue('demand', value);
    form.setValue('realEstateTypes', undefined);
    form.setValue('price', undefined);
    onChange?.();
  };

  return (
    <div id='demand-button' className='flex space-x-[16px]'>
      {map(demands, (demand, demandIndex) => (
        <button
          key={demandIndex}
          type='button'
          disabled={isEqual(demandWatch, demand.value)}
          className={`flex h-[36px] items-center justify-center rounded-[8px] p-[8px] transition duration-[200ms] ease-in-out ${
            isEqual(demandWatch, demand.value)
              ? 'border border-primary bg-secondary'
              : 'bg-tertiary hover:bg-tertiary-light'
          }`}
          onClick={() => {
            handleOnChangeDemand(demand.value);
          }}
        >
          <span
            className={`${
              isEqual(demandWatch, demand.value) ? 'font-[600] text-primary' : ''
            } whitespace-nowrap`}
          >
            {demand.label}
          </span>
        </button>
      ))}
    </div>
  );
};

export default DemandButton;
