import { SearchForm1 } from 'forms';
import { CommonAddress } from 'graphql/main/queries/get-real-estates';
import { filter, includes, isEmpty, isEqual, map, toString } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { removeVietnameseAccents } from 'utils';

interface Props {
  isShowing?: boolean;
  loadingStreets?: boolean;
  streets?: CommonAddress[];
  onToggle?: (popover: string) => void;
}

const StreetPopover = ({ isShowing, loadingStreets, streets, onToggle }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const form = useFormContext<SearchForm1>();
  const districtWatch = useWatch({ control: form.control, name: 'district' });
  const streetWatch = useWatch({ control: form.control, name: 'street' });
  const [privateStreets, setPrivateStreets] = useState(streets);

  const handleTogglePopover = () => {
    onToggle?.('street');
  };
  const handleOnChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const handleOnClearSearchInput = () => {
    setSearchValue('');
  };
  const handleOnSelectStreet = (street: CommonAddress) => {
    form.setValue('street', street);
    setSearchValue('');
    onToggle?.('street');
  };
  const handleOnReset = () => {
    setSearchValue('');
    form.setValue('street', undefined);
    onToggle?.('street');
  };

  useEffect(() => {
    setPrivateStreets(streets);
  }, [streets]);
  useEffect(() => {
    setPrivateStreets(
      filter(streets, (street) =>
        includes(
          removeVietnameseAccents(toString(street.name)),
          removeVietnameseAccents(searchValue),
        ),
      ),
    );
  }, [searchValue]);

  return (
    <div id='street-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <button
        disabled={isEmpty(districtWatch) || loadingStreets}
        type='button'
        className={`flex h-[40px] items-center rounded-[8px] border bg-paper p-[8px] transition-all duration-[200ms] ease-in-out disabled:bg-disabled ${
          isShowing ? 'border-primary' : 'border-stroke'
        }`}
        onClick={handleTogglePopover}
      >
        <span className={`w-full truncate text-left ${streetWatch?.name ? '' : 'text-text2'}`}>
          {streetWatch?.name ?? 'Đường'}
        </span>
        <div className='broder-stroke h-full border-l pl-[8px]'>
          <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px] text-text2' />
        </div>
      </button>
      {isShowing && (
        <div className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
          <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
            <div className='group mx-[16px] flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
              <input
                type='text'
                className='w-full bg-transparent p-[12px] placeholder-text2'
                placeholder='Nhập từ khoá tìm kiếm'
                value={searchValue}
                onChange={handleOnChangeSearchInput}
              />
              {Boolean(searchValue) && (
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={handleOnClearSearchInput}
                >
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              )}
            </div>
            {isEmpty(privateStreets) ? (
              <span className='block h-full px-[16px] italic text-text2'>Không có tuỳ chọn</span>
            ) : (
              <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                {map(privateStreets, (street, streetIndex) => (
                  <li key={`${street.id}-${streetIndex}`}>
                    <button
                      type='button'
                      className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                      onClick={() => {
                        handleOnSelectStreet(street);
                      }}
                    >
                      <div
                        className={`h-[20px] w-[20px] rounded-full border ${
                          isEqual(streetWatch, street)
                            ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                            : 'border-stroke'
                        }`}
                      />
                      <span className='line-clamp-1'>{street.name}</span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
              <button
                type='button'
                className='flex items-center justify-center space-x-[6px]'
                onClick={handleOnReset}
              >
                <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                <span>Đặt lại</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StreetPopover;
