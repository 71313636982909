import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { find, gt, isEqual, isNull, isNumber, isUndefined, map, toString } from 'lodash';
import { ChangeEvent, useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { RangeValue } from 'graphql/main/queries/get-real-estates';
import { numberToStringWithCommas, stringWithCommasToNumber } from 'utils';
import { useConfigurationContext } from 'components/providers/configuration-provider';

interface Props {
  isShowing?: boolean;
  onToggle?: (popover: string) => void;
}

const FloorCountPopover = ({ isShowing, onToggle }: Props) => {
  const translation = useTranslation();
  const form = useFormContext<SearchForm1>();
  const { major } = useConfigurationContext();
  const floorCounts = major?.filter?.common?.floorCount;
  const floorCountWatch = useWatch({ control: form.control, name: 'floorCount' });
  const hasFrom = isNumber(floorCountWatch?.from) && !isEqual(floorCountWatch?.from, 0);
  const hasTo = isNumber(floorCountWatch?.to) && !isUndefined(floorCountWatch?.to);
  const isReverse = gt(floorCountWatch?.from, floorCountWatch?.to);
  const floorCountLabel = floorCountWatch?.key
    ? (translation.major.floorCount as any)[floorCountWatch.key]
    : hasFrom && hasTo
    ? isReverse
      ? `${floorCountWatch?.to?.toLocaleString()} - ${floorCountWatch?.from?.toLocaleString()} tầng`
      : `${floorCountWatch?.from?.toLocaleString()} - ${floorCountWatch?.to?.toLocaleString()} tầng`
    : hasFrom
    ? `${floorCountWatch?.from?.toLocaleString()} tầng trở lên`
    : hasTo
    ? `${floorCountWatch?.to?.toLocaleString()} tầng trở xuống`
    : undefined;

  const handleTogglePopover = () => {
    onToggle?.('floorCount');
  };
  const handleOnChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundFloorCount = find(
      floorCounts,
      (floorCount) =>
        isEqual(floorCount?.from, fromValue) && isEqual(floorCount.to, floorCountWatch?.to),
    );
    form.setValue('floorCount', {
      ...floorCountWatch,
      key: foundFloorCount ? foundFloorCount.key : undefined,
      from: fromValue,
    });
  };
  const handleOnClearFromInput = () => {
    const foundFloorCount = find(
      floorCounts,
      (floorCount) => isEqual(floorCount.from, 0) && isEqual(floorCount.to, floorCountWatch?.to),
    );
    form.setValue('floorCount', {
      ...floorCountWatch,
      key: foundFloorCount ? foundFloorCount.key : undefined,
      from: 0,
    });
  };
  const handleOnChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundFloorCount = find(
      floorCounts,
      (floorCount) =>
        isEqual(floorCount.to, toValue) && isEqual(floorCount.from, floorCountWatch?.from),
    );
    form.setValue('floorCount', {
      ...floorCountWatch,
      key: foundFloorCount ? foundFloorCount.key : undefined,
      to: toValue,
    });
  };
  const handleOnClearToInput = () => {
    const foundFloorCount = find(
      floorCounts,
      (floorCount) => isNull(floorCount.to) && isEqual(floorCount.from, floorCountWatch?.from),
    );
    form.setValue('floorCount', {
      ...floorCountWatch,
      key: foundFloorCount ? foundFloorCount.key : undefined,
      to: null,
    });
  };
  const handleOnSelectFloorCount = (floorCount: RangeValue) => {
    form.setValue('floorCount', floorCount);
    onToggle?.('floorCount');
  };
  const handleOnReset = () => {
    form.setValue('floorCount', undefined);
    onToggle?.('floorCount');
  };
  const handleOnConfirm = () => {
    onToggle?.('floorCount');
  };

  return (
    <div id='floor-count-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <button
        type='button'
        className={`flex h-[40px] items-center rounded-[8px] border bg-paper p-[8px] transition-all duration-[200ms] ease-in-out disabled:cursor-not-allowed disabled:opacity-50 ${
          isShowing ? 'border-primary' : 'border-stroke'
        }`}
        onClick={handleTogglePopover}
      >
        <span className={`w-full truncate text-left ${floorCountLabel ? '' : 'text-text2'}`}>
          {floorCountLabel ?? 'Số tầng'}
        </span>
        <div className='broder-stroke h-full border-l pl-[8px]'>
          <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px] text-text2' />
        </div>
      </button>
      {isShowing && (
        <div className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
          <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
            <div className='flex items-center space-x-[8px] px-[16px]'>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Từ'
                  maxLength={6}
                  value={numberToStringWithCommas(floorCountWatch?.from)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={handleOnChangeFrom}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={handleOnClearFromInput}
                >
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              </div>
              <span>-</span>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Đến'
                  maxLength={6}
                  value={numberToStringWithCommas(floorCountWatch?.to)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={handleOnChangeTo}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={handleOnClearToInput}
                >
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              </div>
            </div>
            <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
              {map(floorCounts, (floorCount, floorCountIndex) => (
                <li key={`${floorCount}-${floorCountIndex}`}>
                  <button
                    type='button'
                    className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={() => {
                      handleOnSelectFloorCount(floorCount);
                    }}
                  >
                    <div
                      className={`h-[20px] w-[20px] rounded-full border ${
                        isEqual(floorCountWatch?.from, floorCount.from) &&
                        isEqual(floorCountWatch?.to, floorCount.to)
                          ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                          : 'border-stroke'
                      }`}
                    />
                    <span className='line-clamp-1'>
                      {(translation.major.floorCount as any)[toString(floorCount.key)]}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
            <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
              <button
                type='button'
                className='flex items-center justify-center space-x-[6px]'
                onClick={handleOnReset}
              >
                <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                <span>Đặt lại</span>
              </button>
              <button
                type='button'
                className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                onClick={handleOnConfirm}
              >
                <span>Xác nhận</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FloorCountPopover;
