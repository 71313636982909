import { isEmpty, isNil, map } from 'lodash';
import Link from 'next/link';
import { useContext } from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';

const NeighborhoodCardList = () => {
  const { nearRealEstates } = useContext(SearchResultPageContext).pageData ?? {};

  return isEmpty(nearRealEstates) ? null : (
    <div id='neighborhood-card-list' className='container mx-auto min-w-[1052px] px-[48px]'>
      <div className='flex flex-col space-y-[24px] rounded-[16px] bg-paper py-[16px] shadow-1'>
        <div className='flex items-center pr-[16px]'>
          <div className='flex items-center space-x-[8px]'>
            <div className='rounded-r-[50px] bg-primary px-[23px] py-[7px]'>
              <IoLocationOutline className='h-[22px] w-[22px] text-paper' />
            </div>
            <h2 className='text-[20px] font-[600] leading-[23px]'>Các bất động sản lân cận</h2>
          </div>
        </div>
        <div className='my-[-8px] flex flex-wrap px-[24px]'>
          {map(nearRealEstates, (nearRealEstate, nearRealEstateIndex) => (
            <div
              key={`${nearRealEstate.districtId}-${nearRealEstateIndex}`}
              className='w-1/4 py-[8px]'
            >
              <Link href={isNil(nearRealEstate?.slug) ? '#' : `/${nearRealEstate?.slug}`}>
                <a>
                  <span className='text-primary'>
                    {nearRealEstate.districtName} ({nearRealEstate.total})
                  </span>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeighborhoodCardList;
