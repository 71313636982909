import { gt, gte, includes, isEqual, lt, lte } from 'lodash';
import { KeyboardEvent } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { PaginationOf } from 'graphql/main/queries/get-user-saved-real-estate';

const SPECIAL_KEYS = [
  'Tab',
  'ArrowLeft',
  'ArrowRight',
  'ArrowTop',
  'ArrowBottom',
  'Delete',
  'Backspace',
  'Enter',
];

interface Props {
  placeholder?: string;
  informationTexts?: [string, string];
  pagination?: PaginationOf;
  realPageSize?: number;
  onChangePage?: (page: number) => void;
}

const Pagination1 = ({
  placeholder,
  informationTexts,
  pagination,
  realPageSize,
  onChangePage,
}: Props) => {
  const countFrom = pagination?.pagingCounter ?? 0;
  const countTo =
    pagination?.pagingCounter && realPageSize ? pagination?.pagingCounter + realPageSize - 1 : 0;
  const isPageExists = gte(countTo, countFrom);
  const hasSelectedPage = Boolean(pagination?.page);
  const isDisabledNextButton = !pagination?.hasNextPage;
  const isDisabledPrevButton = !pagination?.hasPrevPage;
  const isSelectedLastPageLess = lt(pagination?.page, pagination?.totalPages);
  const isSelectedPage3Greater = gt(pagination?.page, 3);
  const isSelectedPage3GreaterEqual = gte(pagination?.page, 3);

  const checkSelectedPage = (page?: number) => isEqual(pagination?.page, page);
  const handleOnKeyDownInput = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { key, code, metaKey } = event;

    if (!metaKey && !includes(SPECIAL_KEYS, code) && key.match(/[^0-9]/)) {
      event.preventDefault();
    }
    if (isEqual(event.key, 'Enter')) {
      const target = event.target as HTMLInputElement;
      const newPage = Number(target.value);
      target.value = '';

      if (newPage && pagination?.totalPages && lte(newPage, pagination?.totalPages)) {
        onChangePage && onChangePage(newPage);
      }
    }
  };
  const handleOnChangePage = (page: number) => {
    onChangePage?.(page);
  };

  return (
    <div id='pagination1' className='flex items-center space-x-[16px]'>
      <span className='select-none'>
        {informationTexts?.[0]}{' '}
        <b>{`${isPageExists ? countFrom.toLocaleString() : 0} - ${
          isPageExists ? countTo.toLocaleString() : 0
        }`}</b>{' '}
        {informationTexts?.[1]}{' '}
        <b>{isPageExists && pagination ? pagination.total?.toLocaleString() : 0}</b>
      </span>
      <div className='flex items-center space-x-[8px]'>
        <button
          type='button'
          className='flex h-[32px] w-[32px] items-center justify-center rounded-[6px] border border-stroke bg-paper p-[4px] transition duration-[200ms] ease-in-out hover:shadow-1 disabled:bg-paper disabled:shadow-none'
          disabled={isDisabledPrevButton}
          onClick={() => {
            pagination?.prevPage && handleOnChangePage(pagination.prevPage);
          }}
        >
          <AiOutlineLeft
            className={`min-h-[16px] min-w-[16px] font-[600] ${
              isDisabledPrevButton ? 'text-text2' : ''
            }`}
          />
        </button>
        {gt(pagination?.totalPages, 0) && (
          <button
            type='button'
            disabled={checkSelectedPage(1)}
            className={`h-[32px] min-w-[32px] rounded-[6px] border p-[7px] transition duration-[200ms] ease-in-out hover:shadow-1 disabled:shadow-none ${
              checkSelectedPage(1)
                ? 'border-primary bg-secondary text-primary'
                : 'border-stroke bg-paper'
            }`}
            onClick={() => {
              handleOnChangePage(1);
            }}
          >
            1
          </button>
        )}
        {gt(pagination?.totalPages, 1) && (
          <button
            type='button'
            disabled={(isSelectedPage3Greater && isSelectedLastPageLess) || checkSelectedPage(2)}
            className={`h-[32px] min-w-[32px] rounded-[6px] border p-[7px] transition duration-[200ms] ease-in-out hover:shadow-1 disabled:shadow-none ${
              checkSelectedPage(2)
                ? 'border-primary bg-secondary text-primary'
                : 'border-stroke bg-paper'
            }`}
            onClick={() => {
              handleOnChangePage(2);
            }}
          >
            {gt(pagination?.page, 3) && lt(pagination?.page, pagination?.totalPages) ? '...' : 2}
          </button>
        )}
        {gt(pagination?.totalPages, 2) && (
          <button
            type='button'
            disabled={
              (hasSelectedPage && isSelectedPage3GreaterEqual && isSelectedLastPageLess) ||
              checkSelectedPage(3)
            }
            className={`h-[32px] min-w-[32px] rounded-[6px] border p-[7px] transition duration-[200ms] ease-in-out hover:shadow-1 disabled:shadow-none ${
              (hasSelectedPage && isSelectedPage3GreaterEqual && isSelectedLastPageLess) ||
              checkSelectedPage(3)
                ? 'border-primary bg-secondary text-primary'
                : 'border-stroke bg-paper'
            }`}
            onClick={() => {
              handleOnChangePage(
                hasSelectedPage && isSelectedPage3Greater && isSelectedLastPageLess
                  ? (pagination?.page as number)
                  : 3,
              );
            }}
          >
            {isSelectedPage3Greater && isSelectedLastPageLess ? pagination?.page : 3}
          </button>
        )}
        <input
          placeholder={placeholder}
          className='h-[32px] w-[135px] rounded-[6px] border border-stroke px-[20px] text-center placeholder-text2 focus:border-primary focus:shadow-1'
          onKeyDown={handleOnKeyDownInput}
        />
        {gt(pagination?.totalPages, 3) && (
          <button
            type='button'
            disabled={checkSelectedPage(pagination?.totalPages)}
            className={`h-[32px] min-w-[32px] rounded-[6px] border p-[7px] transition  duration-[200ms] ease-in-out hover:shadow-1 ${
              checkSelectedPage(pagination?.totalPages)
                ? 'border-primary bg-secondary text-primary'
                : 'border-stroke bg-paper'
            }`}
            onClick={() => {
              pagination?.totalPages && handleOnChangePage(pagination.totalPages);
            }}
          >
            {pagination?.totalPages}
          </button>
        )}
        <button
          type='button'
          className='flex h-[32px] w-[32px] items-center justify-center rounded-[6px] border border-stroke bg-paper p-[4px] transition duration-[200ms] ease-in-out hover:shadow-1 disabled:bg-paper disabled:shadow-none'
          disabled={isDisabledNextButton}
          onClick={() => {
            pagination?.nextPage && handleOnChangePage(pagination.nextPage);
          }}
        >
          <AiOutlineRight
            className={`min-h-[16px] min-w-[16px] font-[600] ${
              isDisabledNextButton ? 'text-text2' : ''
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination1;
