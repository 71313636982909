import AddressPopover from 'components/desktop/search-result/search-tool/filter-list/address-popover';
import AreaPopover from 'components/desktop/search-result/search-tool/filter-list/area-popover';
import MoreFilterPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover';
import PricePopover from 'components/desktop/search-result/search-tool/filter-list/price-popover';
import RealEstateTypePopover from 'components/desktop/search-result/search-tool/filter-list/real-estate-type-popover';
import { SearchForm1 } from 'forms';
import { isEqual, map } from 'lodash';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';

interface Props {
  onSubmit?: () => void;
}

const FilterList = ({ onSubmit }: Props) => {
  const form = useFormContext<SearchForm1>();
  const popovers = [
    <RealEstateTypePopover key={0} onSubmit={onSubmit} />,
    <AddressPopover key={1} onSubmit={onSubmit} />,
    <AreaPopover key={2} onSubmit={onSubmit} />,
    <PricePopover key={3} onSubmit={onSubmit} />,
    <MoreFilterPopover key={4} onSubmit={onSubmit} />,
  ];

  const handleOnResetFilter = () => {
    form.setValue('search', undefined);
    form.setValue('realEstateTypes', undefined);
    form.setValue('province', undefined);
    form.setValue('district', undefined);
    form.setValue('ward', undefined);
    form.setValue('street', undefined);
    form.setValue('price', undefined);
    form.setValue('area', undefined);
    form.setValue('hasAlley', undefined);
    form.setValue('width', undefined);
    form.setValue('length', undefined);
    form.setValue('direction', undefined);
    form.setValue('bedroomCount', undefined);
    form.setValue('toiletCount', undefined);
    form.setValue('floorCount', undefined);
    form.setValue('postType', undefined);
    onSubmit?.();
  };

  return (
    <div id='filter-list' className='flex h-[36px] items-center space-x-[16px]'>
      {map(popovers, (popover, popoverIndex) => (
        <Fragment key={popoverIndex}>
          {!isEqual(popoverIndex, 0) && <div className='h-full w-[1px] bg-stroke py-[4px]' />}
          {popover}
        </Fragment>
      ))}
      <Fragment>
        <div className='h-full w-[1px] bg-stroke' />
        <button
          type='button'
          className='flex h-full items-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary'
          onClick={handleOnResetFilter}
        >
          <BsArrowCounterclockwise className='min-h-[20px] min-w-[20px]' />
          <span className='hidden truncate xl:inline'>Đặt lại</span>
        </button>
      </Fragment>
    </div>
  );
};

export default FilterList;
