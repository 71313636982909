import { Popover } from '@headlessui/react';
import DistrictPopover from 'components/desktop/search-result/search-tool/filter-list/address-popover/district-popover';
import ProvincePopover from 'components/desktop/search-result/search-tool/filter-list/address-popover/province-popover';
import StreetPopover from 'components/desktop/search-result/search-tool/filter-list/address-popover/street-popover';
import WardPopover from 'components/desktop/search-result/search-tool/filter-list/address-popover/ward-popover';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import {
  GET_LIST_DISTRICTS_OF_PROVINCE,
  GetListDistrictsOfProvinceData,
  GetListDistrictsOfProvinceVariables,
} from 'graphql/map/queries/get-list-districts-of-province';
import {
  GET_LIST_STREETS_OF_DISTRICT,
  GetListStreetsOfDistrictData,
  GetListStreetsOfDistrictVariables,
} from 'graphql/map/queries/get-list-streets-of-district';
import {
  GET_LIST_WARDS_OF_DISTRICT,
  GetListWardsOfDistrictData,
  GetListWardsOfDistrictVariables,
} from 'graphql/map/queries/get-list-wards-of-district';
import { useFlexQuery } from 'hooks';
import { compact, isEqual, join, toString } from 'lodash';
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdOutlineArrowDropDown } from 'react-icons/md';

interface Props {
  onSubmit?: () => void;
}

const AddressPopover = ({ onSubmit }: Props) => {
  const [currentPopover, setCurrentPopover] = useState<string | undefined>();
  const isProvincePopoverShowing = isEqual(currentPopover, 'province');
  const isDistrictPopoverShowing = isEqual(currentPopover, 'district');
  const isWardPopoverShowing = isEqual(currentPopover, 'ward');
  const isStreetPopoverShowing = isEqual(currentPopover, 'street');
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const form = useFormContext<SearchForm1>();
  const provinceWatch = useWatch({ control: form.control, name: 'province' });
  const districtWatch = useWatch({ control: form.control, name: 'district' });
  const wardWatch = useWatch({ control: form.control, name: 'ward' });
  const streetWatch = useWatch({ control: form.control, name: 'street' });
  const { provinces } = useConfigurationContext();
  const { data: getListDistrictsOfProvinceData, loading: loadingGetListDistrictsOfProvince } =
    useFlexQuery<GetListDistrictsOfProvinceData, GetListDistrictsOfProvinceVariables>(
      'map',
      GET_LIST_DISTRICTS_OF_PROVINCE,
      {
        variables: {
          provinceId: toString(provinceWatch?.id),
        },
        skip: !provinceWatch?.id,
      },
    );
  const { districts } = getListDistrictsOfProvinceData?.getListDistrictsOfProvince ?? {};
  const { data: getListWardsOfDistrictData, loading: loadingGetListWardsOfDistrict } = useFlexQuery<
    GetListWardsOfDistrictData,
    GetListWardsOfDistrictVariables
  >('map', GET_LIST_WARDS_OF_DISTRICT, {
    variables: {
      districtId: toString(districtWatch?.id),
    },
    skip: !districtWatch?.id,
  });
  const { wards } = getListWardsOfDistrictData?.getListWardsOfDistrict ?? {};
  const { data: getListStreetsOfDistrictData, loading: loadingGetListStreetsOfDistrict } =
    useFlexQuery<GetListStreetsOfDistrictData, GetListStreetsOfDistrictVariables>(
      'map',
      GET_LIST_STREETS_OF_DISTRICT,
      {
        variables: {
          districtId: toString(districtWatch?.id),
        },
        skip: !districtWatch?.id,
      },
    );
  const { streets } = getListStreetsOfDistrictData?.getListStreetsOfDistrict ?? {};
  const addressLabel =
    join(
      compact([streetWatch?.name, wardWatch?.name, districtWatch?.name, provinceWatch?.name]),
      ', ',
    ) || 'Khu vực BĐS';

  const handleOnTogglePopover = (popover: string) => {
    setCurrentPopover(currentPopover ? undefined : popover);
  };
  const handleOnReset = () => {
    form.setValue('province', undefined);
    form.setValue('district', undefined);
    form.setValue('ward', undefined);
    form.setValue('street', undefined);
  };
  const handleOnConfirm = () => {
    popoverButtonRef.current?.click();
    onSubmit?.();
  };

  return (
    <Popover id='address-popover' className='relative flex max-w-[156px] flex-col space-y-[8px]'>
      {({ open }) => (
        <Fragment>
          <Popover.Button
            type='button'
            ref={popoverButtonRef}
            className={`flex h-full items-center space-x-[8px] overflow-x-hidden transition duration-[200ms] ease-in-out hover:text-primary ${
              open ? 'text-primary' : ''
            }`}
          >
            <span className='truncate text-left'>{addressLabel}</span>
            <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px]' />
          </Popover.Button>
          <Popover.Panel className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[16px]'>
            <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper shadow-4'>
              <div className='flex items-center justify-center border-b border-stroke p-[16px]'>
                <span className='text-[16px] font-[600] leading-[25px]'>Khu vực BĐS</span>
              </div>
              <div className='flex flex-col space-y-[16px] px-[16px]'>
                <ProvincePopover
                  isShowing={isProvincePopoverShowing}
                  provinces={provinces}
                  onToggle={handleOnTogglePopover}
                />
                <DistrictPopover
                  isShowing={isDistrictPopoverShowing}
                  loadingDistricts={loadingGetListDistrictsOfProvince}
                  districts={districts}
                  onToggle={handleOnTogglePopover}
                />
                <WardPopover
                  isShowing={isWardPopoverShowing}
                  loadingWards={loadingGetListWardsOfDistrict}
                  wards={wards}
                  onToggle={handleOnTogglePopover}
                />
                <StreetPopover
                  isShowing={isStreetPopoverShowing}
                  loadingStreets={loadingGetListStreetsOfDistrict}
                  streets={streets}
                  onToggle={handleOnTogglePopover}
                />
              </div>
              <div className='flex items-center justify-between border-t border-stroke p-[16px] py-[12px]'>
                <button
                  type='button'
                  className='flex items-center justify-center space-x-[6px]'
                  onClick={handleOnReset}
                >
                  <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                  <span>Đặt lại</span>
                </button>
                <button
                  type='button'
                  className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                  onClick={handleOnConfirm}
                >
                  <span>Xác nhận</span>
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Fragment>
      )}
    </Popover>
  );
};

export default AddressPopover;
