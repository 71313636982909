import { Fragment } from 'react';
import Footer from 'components/desktop/shared/footer';
import Header2 from 'components/desktop/shared/header2';
import MainSection from 'components/desktop/search-result/main-section';
import NeighborhoodCardList from 'components/desktop/search-result/neighborhood-list';
import SearchTool from 'components/desktop/search-result/search-tool';

const SearchResult = () => (
  <Fragment>
    <div className='sticky top-0 right-0 left-0 z-[50] flex min-w-[1052px] flex-col shadow-1'>
      <Header2 />
      <SearchTool />
    </div>
    <main className='flex min-h-[1000px] min-w-[1052px] flex-col'>
      <div className='my-[28px] flex flex-col space-y-[28px]'>
        <MainSection />
        {/* <Advertisment /> */}
        <NeighborhoodCardList />
      </div>
    </main>
    <Footer />
  </Fragment>
);

export default SearchResult;
