import { Popover } from '@headlessui/react';
import BedroomCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/bedroom-count-popover';
import DirectionPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/direction-popover';
import FloorCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/floor-count-popover';
import HasAlleyPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/has-alley-popover';
import LengthPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/length-popover';
import PostTypePopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/post-type-popover';
import ToiletCountPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/toilet-count-popover';
import WidthPopover from 'components/desktop/search-result/search-tool/filter-list/more-filter-popover/width-popover';
import { SearchForm1 } from 'forms';
import { isEqual } from 'lodash';
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { VscFilter } from 'react-icons/vsc';

interface Props {
  onSubmit?: () => void;
}

const MoreFilterPopover = ({ onSubmit }: Props) => {
  const [currentPopover, setCurrentPopover] = useState<string | undefined>();
  const isHasAlleyPopoverShowing = isEqual(currentPopover, 'hasAlley');
  const isDirectionPopoverShowing = isEqual(currentPopover, 'direction');
  const isBedroomCountPopoverShowing = isEqual(currentPopover, 'bedroomCount');
  const isToiletCountPopoverShowing = isEqual(currentPopover, 'toiletCount');
  const isFloorCountPopoverShowing = isEqual(currentPopover, 'floorCount');
  const isPostTypePopoverShowing = isEqual(currentPopover, 'postType');
  const isWidthPopoverShowing = isEqual(currentPopover, 'width');
  const isLengthPopoverShowing = isEqual(currentPopover, 'length');
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const form = useFormContext<SearchForm1>();

  const handleOnTogglePopover = (popover: string) => {
    setCurrentPopover(currentPopover ? undefined : popover);
  };
  const handleOnReset = () => {
    form.setValue('hasAlley', undefined);
    form.setValue('width', undefined);
    form.setValue('length', undefined);
    form.setValue('direction', undefined);
    form.setValue('bedroomCount', undefined);
    form.setValue('toiletCount', undefined);
    form.setValue('floorCount', undefined);
    form.setValue('postType', undefined);
  };
  const handleOnConfirm = () => {
    popoverButtonRef.current?.click();
    onSubmit?.();
  };

  return (
    <Popover
      id='more-filter-popover'
      className='relative flex max-w-[156px] flex-col space-y-[8px]'
    >
      {({ open }) => (
        <Fragment>
          <Popover.Button
            type='button'
            ref={popoverButtonRef}
            className={`flex h-full items-center space-x-[8px] transition duration-[200ms] ease-in-out hover:text-primary ${
              open ? 'text-primary' : ''
            }`}
          >
            <VscFilter className='min-h-[20px] min-w-[20px]' />
            <span className='hidden truncate xl:inline'>Lọc thêm</span>
          </Popover.Button>
          <Popover.Panel className='absolute top-full right-[-16px] z-[2] pt-[16px]'>
            <div className='space-y-[16px] rounded-[8px] bg-paper shadow-4'>
              <div className='flex items-center justify-center border-b border-stroke p-[16px]'>
                <span className='text-[16px] font-[600] leading-[25px]'>Lọc thêm</span>
              </div>
              <div className='flex space-x-[16px] px-[16px]'>
                <div className='flex w-[248px] flex-col space-y-[16px]'>
                  <HasAlleyPopover
                    isShowing={isHasAlleyPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <WidthPopover
                    isShowing={isWidthPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <LengthPopover
                    isShowing={isLengthPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <DirectionPopover
                    isShowing={isDirectionPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                </div>
                <div className='flex w-[248px] flex-col space-y-[16px]'>
                  <BedroomCountPopover
                    isShowing={isBedroomCountPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <ToiletCountPopover
                    isShowing={isToiletCountPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <FloorCountPopover
                    isShowing={isFloorCountPopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                  <PostTypePopover
                    isShowing={isPostTypePopoverShowing}
                    onToggle={handleOnTogglePopover}
                  />
                </div>
              </div>
              <div className='flex items-center justify-between border-t border-stroke px-[16px] py-[12px]'>
                <button
                  type='button'
                  className='flex items-center justify-center space-x-[6px]'
                  onClick={handleOnReset}
                >
                  <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                  <span>Đặt lại</span>
                </button>
                <button
                  type='button'
                  className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                  onClick={handleOnConfirm}
                >
                  <span>Xác nhận</span>
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Fragment>
      )}
    </Popover>
  );
};

export default MoreFilterPopover;
