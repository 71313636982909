import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import { RangeValue } from 'graphql/main/queries/get-real-estates';
import { useTranslation } from 'hooks';
import { find, gt, isEqual, isNull, isNumber, isUndefined, map, toString } from 'lodash';
import { ChangeEvent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { numberToStringWithCommas, stringWithCommasToNumber } from 'utils';

interface Props {
  isShowing?: boolean;
  onToggle?: (popover: string) => void;
}

const BedroomCountPopover = ({ isShowing, onToggle }: Props) => {
  const translation = useTranslation();
  const form = useFormContext<SearchForm1>();
  const { major } = useConfigurationContext();
  const bedroomCounts = major?.common?.bedroomCount;
  const bedroomCountWatch = useWatch({ control: form.control, name: 'bedroomCount' });
  const hasFrom = isNumber(bedroomCountWatch?.from) && !isEqual(bedroomCountWatch?.from, 0);
  const hasTo = isNumber(bedroomCountWatch?.to) && !isUndefined(bedroomCountWatch?.to);
  const isReverse = gt(bedroomCountWatch?.from, bedroomCountWatch?.to);
  const bedroomCountLabel = bedroomCountWatch?.key
    ? (translation.major.bedroomCount as any)[bedroomCountWatch.key]
    : hasFrom && hasTo
    ? isReverse
      ? `${bedroomCountWatch?.to?.toLocaleString()} - ${bedroomCountWatch?.from?.toLocaleString()} phòng`
      : `${bedroomCountWatch?.from?.toLocaleString()} - ${bedroomCountWatch?.to?.toLocaleString()} phòng`
    : hasFrom
    ? `${bedroomCountWatch?.from?.toLocaleString()} phòng trở lên`
    : hasTo
    ? `${bedroomCountWatch?.to?.toLocaleString()} phòng trở xuống`
    : undefined;

  const handleTogglePopover = () => {
    onToggle?.('bedroomCount');
  };
  const handleOnChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundBedroomCount = find(
      bedroomCounts,
      (bedroomCount) =>
        isEqual(bedroomCount?.from, fromValue) && isEqual(bedroomCount.to, bedroomCountWatch?.to),
    );
    form.setValue('bedroomCount', {
      ...bedroomCountWatch,
      key: foundBedroomCount ? foundBedroomCount.key : undefined,
      from: fromValue,
    });
  };
  const handleOnClearFromInput = () => {
    const foundBedroomCount = find(
      bedroomCounts,
      (bedroomCount) =>
        isEqual(bedroomCount.from, 0) && isEqual(bedroomCount.to, bedroomCountWatch?.to),
    );
    form.setValue('bedroomCount', {
      ...bedroomCountWatch,
      key: foundBedroomCount ? foundBedroomCount.key : undefined,
      from: 0,
    });
  };
  const handleOnChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundBedroomCount = find(
      bedroomCounts,
      (bedroomCount) =>
        isEqual(bedroomCount.to, toValue) && isEqual(bedroomCount.from, bedroomCountWatch?.from),
    );
    form.setValue('bedroomCount', {
      ...bedroomCountWatch,
      key: foundBedroomCount ? foundBedroomCount.key : undefined,
      to: toValue,
    });
  };
  const handleOnClearToInput = () => {
    const foundBedroomCount = find(
      bedroomCounts,
      (bedroomCount) =>
        isNull(bedroomCount.to) && isEqual(bedroomCount.from, bedroomCountWatch?.from),
    );
    form.setValue('bedroomCount', {
      ...bedroomCountWatch,
      key: foundBedroomCount ? foundBedroomCount.key : undefined,
      to: null,
    });
  };
  const handleOnSelectBedroomCount = (bedroomCount: RangeValue) => {
    form.setValue('bedroomCount', bedroomCount);
    onToggle?.('bedroomCount');
  };
  const handleOnReset = () => {
    form.setValue('bedroomCount', undefined);
    onToggle?.('bedroomCount');
  };
  const handleOnConfirm = () => {
    onToggle?.('bedroomCount');
  };

  return (
    <div id='bedroom-count-popover' className='relative flex w-full min-w-0 flex-col space-y-[8px]'>
      <button
        type='button'
        className={`flex h-[40px] items-center rounded-[8px] border bg-paper p-[8px] transition-all duration-[200ms] ease-in-out disabled:cursor-not-allowed disabled:opacity-50 ${
          isShowing ? 'border-primary' : 'border-stroke'
        }`}
        onClick={handleTogglePopover}
      >
        <span className={`w-full truncate text-left ${bedroomCountLabel ? '' : 'text-text2'}`}>
          {bedroomCountLabel ?? 'Số phòng ngủ'}
        </span>
        <div className='broder-stroke h-full border-l pl-[8px]'>
          <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px] text-text2' />
        </div>
      </button>
      {isShowing && (
        <div className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[4px]'>
          <div className='w-[312px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
            <div className='flex items-center space-x-[8px] px-[16px]'>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Từ'
                  maxLength={6}
                  value={numberToStringWithCommas(bedroomCountWatch?.from)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={handleOnChangeFrom}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={handleOnClearFromInput}
                >
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              </div>
              <span>-</span>
              <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                <NumericFormat
                  thousandSeparator
                  autoComplete='off'
                  placeholder='Đến'
                  maxLength={6}
                  value={numberToStringWithCommas(bedroomCountWatch?.to)}
                  className='w-full bg-transparent p-[12px] placeholder-text2'
                  onChange={handleOnChangeTo}
                />
                <button
                  type='button'
                  className='ml-[8px] hidden group-hover:flex'
                  onClick={handleOnClearToInput}
                >
                  <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                </button>
              </div>
            </div>
            <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
              {map(bedroomCounts, (bedroomCount, bedroomCountIndex) => (
                <li key={`${bedroomCount}-${bedroomCountIndex}`}>
                  <button
                    type='button'
                    className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                    onClick={() => {
                      handleOnSelectBedroomCount(bedroomCount);
                    }}
                  >
                    <div
                      className={`h-[20px] w-[20px] rounded-full border ${
                        isEqual(bedroomCountWatch?.from, bedroomCount.from) &&
                        isEqual(bedroomCountWatch?.to, bedroomCount.to)
                          ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                          : 'border-stroke'
                      }`}
                    />
                    <span className='line-clamp-1'>
                      {(translation.major.bedroomCount as any)[toString(bedroomCount.key)]}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
            <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
              <button
                type='button'
                className='flex items-center justify-center space-x-[6px]'
                onClick={handleOnReset}
              >
                <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                <span>Đặt lại</span>
              </button>
              <button
                type='button'
                className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                onClick={handleOnConfirm}
              >
                <span>Xác nhận</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BedroomCountPopover;
