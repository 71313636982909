import DemandButton from 'components/desktop/search-result/search-tool/demand-button';
import FilterList from 'components/desktop/search-result/search-tool/filter-list';
import SearchInput from 'components/desktop/search-result/search-tool/search-input';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { searchFormDefaultValues1 } from 'forms';
import { Direction } from 'graphql/main/queries/get-real-estates';
import { SLUGERY, SlugeryData, SlugeryVariables } from 'graphql/main/queries/slugery';
import {
  GET_LIST_DISTRICTS_OF_PROVINCE,
  GetListDistrictsOfProvinceData,
  GetListDistrictsOfProvinceVariables,
} from 'graphql/map/queries/get-list-districts-of-province';
import {
  GET_LIST_STREETS_OF_DISTRICT,
  GetListStreetsOfDistrictData,
  GetListStreetsOfDistrictVariables,
} from 'graphql/map/queries/get-list-streets-of-district';
import {
  GET_LIST_WARDS_OF_DISTRICT,
  GetListWardsOfDistrictData,
  GetListWardsOfDistrictVariables,
} from 'graphql/map/queries/get-list-wards-of-district';
import { TypeOfDemand } from 'graphql/map/queries/get-suggestions';
import { useFlexLazyQuery } from 'hooks';
import {
  concat,
  find,
  gt,
  isArray,
  isEmpty,
  isEqual,
  isNull,
  isNumber,
  isString,
  join,
  toNumber,
  toString,
} from 'lodash';
import { useRouter } from 'next/router';
import { SearchResultPageContext } from 'pages/[ket-qua-tim-kiem]';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const SearchTool = () => {
  const { slugify } = useContext(SearchResultPageContext).pageData ?? {};
  const { filters } = slugify ?? {};
  const { typeOfDemand, realEstateType, streetId, wardId, districtId, provinceId, area, price } =
    filters as any;
  const router = useRouter();
  const routerQueries = router.query;
  const form = useForm({
    defaultValues: searchFormDefaultValues1,
  });
  const [slugery] = useFlexLazyQuery<SlugeryData, SlugeryVariables>('main', SLUGERY);
  const { provinces } = useConfigurationContext();
  const [getListDistrictsOfProvince] = useFlexLazyQuery<
    GetListDistrictsOfProvinceData,
    GetListDistrictsOfProvinceVariables
  >('map', GET_LIST_DISTRICTS_OF_PROVINCE);
  const [getListWardsOfDistrict] = useFlexLazyQuery<
    GetListWardsOfDistrictData,
    GetListWardsOfDistrictVariables
  >('map', GET_LIST_WARDS_OF_DISTRICT);
  const [getListStreetsOfDistrict] = useFlexLazyQuery<
    GetListStreetsOfDistrictData,
    GetListStreetsOfDistrictVariables
  >('map', GET_LIST_STREETS_OF_DISTRICT);

  const handleOnSubmit = async () => {
    const data = form.getValues();
    const isReverseArea = !isNull(data.area?.to) && gt(data.area?.from, data.area?.to);
    const isReversePrice = !isNull(data.price?.to) && gt(data.price?.from, data.price?.to);
    const isReverseWidth = !isNull(data.width?.to) && gt(data.width?.from, data.width?.to);
    const isReverseLength = !isNull(data.length?.to) && gt(data.length?.from, data.length?.to);
    const isReverseBedroomCount =
      !isNull(data.bedroomCount?.to) && gt(data.bedroomCount?.from, data.bedroomCount?.to);
    const isReverseToiletCount =
      !isNull(data.toiletCount?.to) && gt(data.toiletCount?.from, data.toiletCount?.to);
    const isReverseFloorCount =
      !isNull(data.floorCount?.to) && gt(data.floorCount?.from, data.floorCount?.to);
    const slugeryData = await slugery({
      variables: {
        search: data.search,
        demandType: data.demand,
        type: data.realEstateTypes,
        address:
          data.province || data.district || data.ward || data.street
            ? {
                province: data.province ?? undefined,
                district: data.district ?? undefined,
                ward: data.ward ?? undefined,
                street: data.street ?? undefined,
              }
            : undefined,
        area: data.area
          ? isReverseArea
            ? {
                from: data.area.to,
                to: data.area.from,
              }
            : {
                from: data.area.from,
                to: data.area.to,
              }
          : undefined,
        price: data.price
          ? isReversePrice
            ? {
                from: data.price.to,
                to: data.price.from,
              }
            : {
                from: data.price.from,
                to: data.price.to,
              }
          : undefined,
        hasAlley: isEmpty(data.hasAlley) ? undefined : isEqual(data.hasAlley, 'true'),
        width: data.width
          ? isReverseWidth
            ? {
                from: data.width.to,
                to: data.width.from,
              }
            : {
                from: data.width.from,
                to: data.width.to,
              }
          : undefined,
        length: data.length
          ? isReverseLength
            ? {
                from: data.length.to,
                to: data.length.from,
              }
            : {
                from: data.length.from,
                to: data.length.to,
              }
          : undefined,
        direction: isEmpty(data.direction) ? undefined : data.direction,
        bedroomCount: data.bedroomCount
          ? isReverseBedroomCount
            ? {
                from: data.bedroomCount.to,
                to: data.bedroomCount.from,
              }
            : {
                from: data.bedroomCount.from,
                to: data.bedroomCount.to,
              }
          : undefined,
        toiletCount: data.toiletCount
          ? isReverseToiletCount
            ? {
                from: data.toiletCount.to,
                to: data.toiletCount.from,
              }
            : {
                from: data.toiletCount.from,
                to: data.toiletCount.to,
              }
          : undefined,
        floorCount: data.floorCount
          ? isReverseFloorCount
            ? {
                from: data.floorCount.to,
                to: data.floorCount.from,
              }
            : {
                from: data.floorCount.from,
                to: data.floorCount.to,
              }
          : undefined,
        postType: isEmpty(data.postType) ? undefined : toNumber(data.postType),
      },
    });
    if (slugeryData.data?.slugery?.slug) {
      const parsedUrl = queryString.parseUrl(slugeryData.data.slugery.slug);
      router.push({
        pathname: parsedUrl.url,
        query: isEmpty(routerQueries.sort)
          ? queryString.stringify(parsedUrl.query)
          : join(concat((queryString.stringify(parsedUrl.query), `&sort=${routerQueries.sort}`))),
      });
    }
  };

  useEffect(() => {
    form.setValue('search', toString(routerQueries.search));
  }, [routerQueries.search]);
  useEffect(() => {
    form.setValue('demand', isEqual(typeOfDemand, TypeOfDemand.ForRent) ? 'rent' : 'sell');
  }, [typeOfDemand]);
  useEffect(() => {
    form.setValue(
      'realEstateTypes',
      isArray(realEstateType)
        ? realEstateType[0]
        : isString(realEstateType)
        ? realEstateType
        : undefined,
    );
    // form.setValue('realEstateTypes', realEstateType ? [realEstateType] : undefined);
  }, [realEstateType]);
  useEffect(() => {
    (async () => {
      form.setValue(
        'province',
        find(provinces, (province) => isEqual(province.id, provinceId)),
      );
      if (provinceId) {
        const getListDistrictsOfProvinceData = await getListDistrictsOfProvince({
          variables: {
            provinceId,
          },
        });
        const { districts } = getListDistrictsOfProvinceData.data?.getListDistrictsOfProvince ?? {};
        form.setValue(
          'district',
          find(districts, (district) => isEqual(district.id, districtId)),
        );
        if (districtId) {
          const getListWardsOfDistrictData = await getListWardsOfDistrict({
            variables: {
              districtId,
            },
          });
          const { wards } = getListWardsOfDistrictData.data?.getListWardsOfDistrict ?? {};
          form.setValue(
            'ward',
            find(wards, (ward) => isEqual(ward.id, wardId)),
          );
          const getListStreetsOfDistrictData = await getListStreetsOfDistrict({
            variables: {
              districtId,
            },
          });
          const { streets } = getListStreetsOfDistrictData.data?.getListStreetsOfDistrict ?? {};
          form.setValue(
            'street',
            find(streets, (street) => isEqual(street.id, streetId)),
          );
        }
      }
    })();
  }, [provinceId, districtId, wardId, streetId]);
  useEffect(() => {
    const hasAreaFrom = routerQueries.areaFrom && isNumber(toNumber(routerQueries.areaFrom));
    const hasAreaTo = routerQueries.areaTo && isNumber(toNumber(routerQueries.areaTo));
    form.setValue(
      'area',
      area
        ? { from: area.from, to: area.to }
        : hasAreaFrom || hasAreaTo
        ? !hasAreaFrom
          ? { from: 0, to: toNumber(routerQueries.areaTo) }
          : !hasAreaTo
          ? { from: toNumber(routerQueries.areaFrom), to: null }
          : { from: toNumber(routerQueries.areaFrom), to: toNumber(routerQueries.areaTo) }
        : undefined,
    );
  }, [area, routerQueries.areaFrom, routerQueries.areaTo]);
  useEffect(() => {
    const hasPriceFrom = routerQueries.priceFrom && isNumber(toNumber(routerQueries.priceFrom));
    const hasPriceTo = routerQueries.priceTo && isNumber(toNumber(routerQueries.priceTo));
    form.setValue(
      'price',
      price
        ? { from: price.from, to: price.to }
        : hasPriceFrom || hasPriceTo
        ? !hasPriceFrom
          ? { from: 0, to: toNumber(routerQueries.priceTo) }
          : !hasPriceTo
          ? { from: toNumber(routerQueries.priceFrom), to: null }
          : { from: toNumber(routerQueries.priceFrom), to: toNumber(routerQueries.priceTo) }
        : undefined,
    );
  }, [price, routerQueries.priceFrom, routerQueries.priceTo]);
  useEffect(() => {
    form.setValue('hasAlley', toString(routerQueries.hasAlley));
  }, [routerQueries.hasAlley]);
  useEffect(() => {
    const hasWidthFrom = routerQueries.widthFrom && isNumber(toNumber(routerQueries.widthFrom));
    const hasWidthTo = routerQueries.widthTo && isNumber(toNumber(routerQueries.widthTo));
    form.setValue(
      'width',
      hasWidthFrom || hasWidthTo
        ? !hasWidthFrom
          ? { from: 0, to: toNumber(routerQueries.widthTo) }
          : !hasWidthTo
          ? { from: toNumber(routerQueries.widthFrom), to: null }
          : { from: toNumber(routerQueries.widthFrom), to: toNumber(routerQueries.widthTo) }
        : undefined,
    );
  }, [routerQueries.widthFrom, routerQueries.widthTo]);
  useEffect(() => {
    const hasLengthFrom = routerQueries.lengthFrom && isNumber(toNumber(routerQueries.lengthFrom));
    const hasLengthTo = routerQueries.lengthTo && isNumber(toNumber(routerQueries.lengthTo));
    form.setValue(
      'length',
      hasLengthFrom || hasLengthTo
        ? !hasLengthFrom
          ? { from: 0, to: toNumber(routerQueries.lengthTo) }
          : !hasLengthTo
          ? { from: toNumber(routerQueries.lengthFrom), to: null }
          : { from: toNumber(routerQueries.lengthFrom), to: toNumber(routerQueries.lengthTo) }
        : undefined,
    );
  }, [routerQueries.lengthFrom, routerQueries.lengthTo]);
  useEffect(() => {
    const hasBedroomCountFrom =
      routerQueries.bedroomCountFrom && isNumber(toNumber(routerQueries.bedroomCountFrom));
    const hasBedroomCountTo =
      routerQueries.bedroomCountTo && isNumber(toNumber(routerQueries.bedroomCountTo));
    form.setValue(
      'bedroomCount',
      hasBedroomCountFrom || hasBedroomCountTo
        ? !hasBedroomCountFrom
          ? { from: 0, to: toNumber(routerQueries.bedroomCountTo) }
          : !hasBedroomCountTo
          ? { from: toNumber(routerQueries.bedroomCountFrom), to: null }
          : {
              from: toNumber(routerQueries.bedroomCountFrom),
              to: toNumber(routerQueries.bedroomCountTo),
            }
        : undefined,
    );
  }, [routerQueries.bedroomCountFrom, routerQueries.bedroomCountTo]);
  useEffect(() => {
    const hasToiletCountFrom =
      routerQueries.toiletCountFrom && isNumber(toNumber(routerQueries.toiletCountFrom));
    const hasToiletCountTo =
      routerQueries.toiletCountTo && isNumber(toNumber(routerQueries.toiletCountTo));
    form.setValue(
      'toiletCount',
      hasToiletCountFrom || hasToiletCountTo
        ? !hasToiletCountFrom
          ? { from: 0, to: toNumber(routerQueries.toiletCountTo) }
          : !hasToiletCountTo
          ? { from: toNumber(routerQueries.toiletCountFrom), to: null }
          : {
              from: toNumber(routerQueries.toiletCountFrom),
              to: toNumber(routerQueries.toiletCountTo),
            }
        : undefined,
    );
  }, [routerQueries.toiletCountFrom, routerQueries.toiletCountTo]);
  useEffect(() => {
    const hasFloorCountFrom =
      routerQueries.floorCountFrom && isNumber(toNumber(routerQueries.floorCountFrom));
    const hasFloorCountTo =
      routerQueries.floorCountTo && isNumber(toNumber(routerQueries.floorCountTo));
    form.setValue(
      'floorCount',
      hasFloorCountFrom || hasFloorCountTo
        ? !hasFloorCountFrom
          ? { from: 0, to: toNumber(routerQueries.floorCountTo) }
          : !hasFloorCountTo
          ? { from: toNumber(routerQueries.floorCountFrom), to: null }
          : {
              from: toNumber(routerQueries.floorCountFrom),
              to: toNumber(routerQueries.floorCountTo),
            }
        : undefined,
    );
  }, [routerQueries.floorCountFrom, routerQueries.floorCountTo]);
  useEffect(() => {
    form.setValue('direction', routerQueries.direction as Direction);
  }, [routerQueries.direction]);
  useEffect(() => {
    form.setValue('postType', toString(routerQueries.postType));
  }, [routerQueries.postType]);

  return (
    <div id='search-tool' className='bg-paper'>
      <form
        autoComplete='off'
        className='flex items-center space-x-[16px] py-[12px] px-[16px]'
        onSubmit={(event) => {
          event.preventDefault();
          handleOnSubmit();
        }}
      >
        <FormProvider {...form}>
          <DemandButton onChange={handleOnSubmit} />
          <SearchInput />
          <FilterList onSubmit={handleOnSubmit} />
        </FormProvider>
      </form>
    </div>
  );
};

export default SearchTool;
