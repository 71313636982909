import { Popover } from '@headlessui/react';
import { useConfigurationContext } from 'components/providers/configuration-provider';
import { SearchForm1 } from 'forms';
import { useTranslation } from 'hooks';
import { find, gt, isEqual, isNull, isNumber, isUndefined, map, toString } from 'lodash';
import { ChangeEvent, Fragment, MutableRefObject, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { MdClear, MdOutlineArrowDropDown } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { numberToStringWithCommas, stringWithCommasToNumber } from 'utils';
import { RangeValue } from 'graphql/main/queries/get-real-estates';

interface Props {
  onSubmit?: () => void;
}

const AreaPopover = ({ onSubmit }: Props) => {
  const translation = useTranslation();
  const popoverButtonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const { major } = useConfigurationContext();
  const areas = major?.common?.area;
  const form = useFormContext<SearchForm1>();
  const areaWatch = useWatch({ control: form.control, name: 'area' });
  const hasFrom = isNumber(areaWatch?.from) && !isEqual(areaWatch?.from, 0);
  const hasTo = isNumber(areaWatch?.to) && !isUndefined(areaWatch?.to);
  const isReverse = gt(areaWatch?.from, areaWatch?.to);
  const areaLabel = areaWatch?.key
    ? (translation.major.area as any)[areaWatch.key]
    : hasFrom && hasTo
    ? isReverse
      ? `${areaWatch?.to?.toLocaleString()} - ${areaWatch?.from?.toLocaleString()} m\u00B2`
      : `${areaWatch?.from?.toLocaleString()} - ${areaWatch?.to?.toLocaleString()} m\u00B2`
    : hasFrom
    ? `${areaWatch?.from?.toLocaleString()} m\u00B2 trở lên`
    : hasTo
    ? `${areaWatch?.to?.toLocaleString()} m\u00B2 trở xuống`
    : 'Diện tích';

  const handleOnChangeFrom = (event: ChangeEvent<HTMLInputElement>) => {
    const fromValue = stringWithCommasToNumber(event.target.value);
    const foundArea = find(
      areas,
      (area) => isEqual(area.from, fromValue) && isEqual(area.to, areaWatch?.to),
    );
    form.setValue('area', {
      ...areaWatch,
      key: foundArea ? foundArea.key : undefined,
      from: fromValue,
    });
  };
  const handleOnClearFromInput = () => {
    const foundArea = find(
      areas,
      (area) => isEqual(area.from, 0) && isEqual(area.to, areaWatch?.to),
    );
    form.setValue('area', {
      ...areaWatch,
      key: foundArea ? foundArea.key : undefined,
      from: 0,
    });
  };
  const handleOnChangeTo = (event: ChangeEvent<HTMLInputElement>) => {
    const toValue = stringWithCommasToNumber(event.target.value);
    const foundArea = find(
      areas,
      (area) => isEqual(area.to, toValue) && isEqual(area.from, areaWatch?.from),
    );
    form.setValue('area', {
      ...areaWatch,
      key: foundArea ? foundArea.key : undefined,
      to: toValue,
    });
  };
  const handleOnClearToInput = () => {
    const foundArea = find(areas, (area) => isNull(area.to) && isEqual(area.from, areaWatch?.from));
    form.setValue('area', {
      ...areaWatch,
      key: foundArea ? foundArea.key : undefined,
      to: null,
    });
  };
  const handleOnSelectArea = (area: RangeValue) => {
    form.setValue('area', area);
    popoverButtonRef.current?.click();
    onSubmit?.();
  };
  const handleOnReset = () => {
    form.setValue('area', undefined);
    popoverButtonRef.current?.click();
    onSubmit?.();
  };
  const handleOnConfirm = () => {
    popoverButtonRef.current?.click();
    onSubmit?.();
  };

  return (
    <Popover id='area-popover' className='relative flex max-w-[156px] flex-col space-y-[8px]'>
      {({ open }) => (
        <Fragment>
          <Popover.Button
            type='button'
            ref={popoverButtonRef}
            className={`flex h-full items-center space-x-[8px] overflow-x-hidden transition duration-[200ms] ease-in-out hover:text-primary ${
              open ? 'text-primary' : ''
            }`}
          >
            <span className='truncate text-left'>{areaLabel}</span>
            <MdOutlineArrowDropDown className='min-h-[24px] min-w-[24px]' />
          </Popover.Button>
          <Popover.Panel className='absolute top-full left-1/2 z-[2] -translate-x-1/2 pt-[16px]'>
            <div className='w-[280px] space-y-[16px] rounded-[8px] bg-paper pt-[16px] shadow-4'>
              <div className='flex items-center space-x-[8px] px-[16px]'>
                <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                  <NumericFormat
                    thousandSeparator
                    autoComplete='off'
                    placeholder='Từ'
                    maxLength={6}
                    value={numberToStringWithCommas(areaWatch?.from)}
                    className='w-full bg-transparent p-[12px] placeholder-text2'
                    onChange={handleOnChangeFrom}
                  />
                  <button
                    type='button'
                    className='ml-[8px] hidden group-hover:flex'
                    onClick={handleOnClearFromInput}
                  >
                    <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                  </button>
                </div>
                <span>-</span>
                <div className='group flex h-[40px] cursor-text items-center rounded-[8px] border border-stroke pr-[8px]'>
                  <NumericFormat
                    thousandSeparator
                    autoComplete='off'
                    placeholder='Đến'
                    maxLength={6}
                    value={numberToStringWithCommas(areaWatch?.to)}
                    className='w-full bg-transparent p-[12px] placeholder-text2'
                    onChange={handleOnChangeTo}
                  />
                  <button
                    type='button'
                    className='ml-[8px] hidden group-hover:flex'
                    onClick={handleOnClearToInput}
                  >
                    <MdClear className='min-h-[24px] min-w-[24px] text-text2' />
                  </button>
                </div>
              </div>
              <ul className='flex max-h-[208px] flex-col space-y-[12px] overflow-y-auto'>
                {map(areas, (area, areaIndex) => (
                  <li key={`${area}-${areaIndex}`}>
                    <button
                      type='button'
                      className='flex h-[32px] w-full items-center space-x-[8px] px-[16px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                      onClick={() => {
                        handleOnSelectArea(area);
                      }}
                    >
                      <div
                        className={`h-[20px] w-[20px] rounded-full border ${
                          isEqual(areaWatch?.from, area.from) && isEqual(areaWatch?.to, area.to)
                            ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                            : 'border-stroke'
                        }`}
                      />
                      <span className='line-clamp-1'>
                        {(translation.major.area as any)[toString(area.key)]}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
              <div className='flex items-center justify-between border-t border-stroke p-[16px]'>
                <button
                  type='button'
                  className='flex items-center justify-center space-x-[6px]'
                  onClick={handleOnReset}
                >
                  <BsArrowCounterclockwise className='min-h-[16px] min-w-[16px]' />
                  <span>Đặt lại</span>
                </button>
                <button
                  type='button'
                  className='flex h-[38px] items-center justify-center space-x-[6px] rounded-[8px] bg-primary px-[16px] py-[8px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                  onClick={handleOnConfirm}
                >
                  <span>Xác nhận</span>
                </button>
              </div>
            </div>
          </Popover.Panel>
        </Fragment>
      )}
    </Popover>
  );
};

export default AreaPopover;
