import { SearchForm1 } from 'forms';
import { useFlexQuery } from 'hooks';
import { isEmpty, isEqual, toString } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IoSearchOutline } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';
import Typewriter from 'typewriter-effect';
import { DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER } from 'globalConstants';
import {
  GET_SUGGESTIONS,
  GetSuggestionsData,
  GetSuggestionsVariables,
  TypeOfDemand,
} from 'graphql/map/queries/get-suggestions';
import SuggestionPanel from 'components/desktop/shared/suggestion-panel';

const SearchInput = () => {
  const [placeholder, setPlaceholder] = useState(DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER);
  const [isFocusSearchInput, setIsFocusSearchInput] = useState(false);
  const [isShowTypeWritter, setIsShowTypeWritter] = useState(false);
  const [isFocusInput, setIsFocusInput] = useState(false);
  const form = useFormContext<SearchForm1>();
  const searchRegister = form.register('search');
  const searchWatch = useWatch({ control: form.control, name: 'search' });
  const demandWatch = useWatch({ control: form.control, name: 'demand' });
  const { data } = useFlexQuery<GetSuggestionsData, GetSuggestionsVariables>(
    'map',
    GET_SUGGESTIONS,
    {
      variables: {
        search: toString(searchWatch),
        typeOfDemand: isEqual(demandWatch, 'rent') ? TypeOfDemand.ForRent : TypeOfDemand.ForSale,
      },
      skip: !searchWatch,
    },
  );
  const { getSuggestions: suggestions } = data ?? {};

  const handleOnFocusInput = () => {
    setPlaceholder('');
    setIsFocusSearchInput(true);
    setIsShowTypeWritter(isEmpty(searchWatch));
    setIsFocusInput(true);
  };
  const handleOnBlurInput = () => {
    setPlaceholder(DEFAULT_MAIN_SEARCH_TOOL_PLACEHOLDER);
    setIsFocusSearchInput(false);
    setIsShowTypeWritter(false);
    setIsFocusInput(false);
  };
  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setIsShowTypeWritter(isEmpty(event.target.value));
  };
  const handleOnClearInput = () => {
    form.setValue('search', undefined);
  };

  return (
    <div
      id='search-input'
      className='relative flex h-[36px] w-full items-center rounded-[6px] border border-stroke focus-within:border-primary'
    >
      <input
        {...searchRegister}
        id='search'
        type='text'
        placeholder={placeholder}
        className='h-full w-full truncate bg-transparent py-[6px] pl-[8px] placeholder-text2'
        onFocus={handleOnFocusInput}
        onBlur={(event) => {
          searchRegister.onBlur(event);
          handleOnBlurInput();
        }}
        onChange={(event) => {
          searchRegister.onChange(event);
          handleOnChangeInput(event);
        }}
      />
      {isShowTypeWritter && (
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
            deleteSpeed: 1,
            delay: 50,
            strings: ['Vinhomes', '138 Lý Thường Kiệt', 'Biệt thự ven sông chính chủ'],
            wrapperClassName:
              'text-text2 absolute left-[8px] top-1/2 -translate-y-1/2 pointer-events-none',
            cursorClassName: 'hidden',
          }}
        />
      )}
      {Boolean(searchWatch) && (
        <button type='button' onClick={handleOnClearInput}>
          <MdClear className='ml-[8px] min-h-[24px] min-w-[24px] text-text2' />
        </button>
      )}
      <div className='ml-[8px] border-l border-stroke px-[8px]'>
        <IoSearchOutline className='min-h-[24px] min-w-[24px] text-text2' />
      </div>
      {isFocusSearchInput && isFocusInput && (
        <SuggestionPanel
          isShowing={isFocusInput && Boolean(searchWatch)}
          keyword={searchWatch}
          suggestions={suggestions}
        />
      )}
    </div>
  );
};

export default SearchInput;
